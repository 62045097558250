<template>
  <v-container>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="900"
      max-height="900"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          style="background-color: #147D81;color: white"
          v-bind="attrs"
          v-on="on"
          >Contract template aanmaken</v-btn
        >
      </template>

      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="#147D81" dark>Contract template aanmaken</v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="title" label="Template titel">
                  Titel contract template</v-text-field
                >
                <vue-editor
                  id="editor1"
                  v-model="content"
                  ref="editor"
                  @drop="dropText"
                  @dragover.prevent
                ></vue-editor>
              </v-col>
              <v-col cols="4" style="margin-top:70px">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Huurder</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <draggable v-model="huurderVariables">
                        <v-list-item
                          v-for="item in huurderVariables"
                          :key="item"
                          draggable="true"
                          @dragstart="startDrag($event, item)"
                        >
                          <v-list-item-content>
                            {{ item }}
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>Relatie</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <draggable v-model="relatieVariables">
                        <v-list-item
                          v-for="item in relatieVariables"
                          :key="item"
                          draggable="true"
                          @dragstart="startDrag($event, item)"
                        >
                          <v-list-item-content>
                            {{ item }}
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>Object</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <draggable v-model="objectVariables">
                        <v-list-item
                          v-for="item in objectVariables"
                          :key="item"
                          draggable="true"
                          @dragstart="startDrag($event, item)"
                        >
                          <v-list-item-content>
                            {{ item }}
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <div class="text-center">
              <v-btn
                style="background-color: #147D81;
  color: white;margin-right:10px"
                @click="createContractTemplate()"
              >
                Aanmaken
              </v-btn>
              <v-btn
                style="color:white;background-color:#cb403d"
                @click="dialog.value = false"
              >
                Afsluiten
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-row style="margin-top:5px">
      <v-col cols="6">
        <v-text-field
          v-model="searchTerm"
          label="Contract template opzoeken"
          hint="(hint) Zoek op titel"
          prepend-icon="mdi-magnify"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row style="margin-top:2rem">
      <v-col v-for="item in filteredTemplates" :key="item.id" cols="4">
        <v-card class="mx-auto" max-width="344">
          <v-card-text>
            <div>
              <b>{{ item.title }}</b>
            </div>
            <div class="text--primary">Aanmaakdatum {{ item.createDate }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="openEditDialog(item)"
              style="background-color: #147D81;color:white;"
              small
            >
              Template bekijken
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="900px">
      <v-card>
        <v-toolbar color="#147D81" dark>Contract template bewerken</v-toolbar>
        <v-card-text>
          <v-text-field v-model="editTitle" label="Template titel"
            >Titel contract template</v-text-field
          >
          <vue-editor v-model="editContent"></vue-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="updateContractTemplate()"
            >Opslaan</v-btn
          >
          <v-btn
            style="background-color:red;
  color: white;"
            @click="editDialog = false"
            >Annuleren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Draggable from "vuedraggable";
import { VueEditor } from "vue2-editor";
import apiContractTemplate from "../api/apiContractTemplate";
export default {
  components: {
    VueEditor,
    Draggable,
  },

  data() {
    return {
      draggedItem: null,
      huurderVariables: [
        "{{firstName}}",
        "{{lastName}}",
        "{{email}}",
        "{{Phone}}",
      ],
      relatieVariables: [
        "{{firstName}}",
        "{{lastName}}",
        "{{email}}",
        "{{Phone}}",
      ],
      objectVariables: ["{{adres}}"],
      contractVariables: [
        "{{contractNumber}}",
        "startDate",
        "endDate",
        "cancellationDate",
        "extensionPeriod",
      ],
      editDialog: false,
      searchTerm: "",
      editTitle: "",
      editContent: "",
      selectedTemplateId: null,
      htmlForEditor: "",
      title: "",
      content: "",
      templates: [],
    };
  },
  computed: {
    filteredTemplates() {
      if (!this.searchTerm) {
        return this.templates;
      }
      return this.templates.filter((template) =>
        template.title.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },

  async mounted() {
    this.$nextTick(() => {
      if (this.$refs.editor) {
        this.$refs.editor.$el.addEventListener("drop", this.dropText);
      }
    });
    apiContractTemplate.getContractsTemplate().then((data) => {
      this.templates = data;
    });
  },
  methods: {
    startDrag(event, item) {
      console.log("Dragging:", item);
      event.dataTransfer.setData("text/plain", item);
    },
    dropText(event) {
      event.preventDefault();
      const droppedText = event.dataTransfer.getData("text/plain");

      if (!droppedText) return;

      const editor = this.$refs.editor.quill;
      if (!editor) {
        console.error("Editor is not defined");
        return;
      }

      const range = editor.getSelection();
      if (range) {
        editor.insertText(range.index, droppedText);
      } else {
        this.content += droppedText;
      }
    },

    openEditDialog(template) {
      this.editTitle = template.title;
      this.editContent = template.content;
      this.selectedTemplateId = template.id;
      this.editDialog = true;
    },
    async updateContractTemplate() {
      try {
        const data = {
          title: this.title,
          content: this.content,
        };
        await apiContractTemplate.updateContractTemplate(data);

        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
        this.templates = await apiContractTemplate.getContractsTemplate();
        this.emplployee = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },
    async createContractTemplate() {
      try {
        const data = {
          title: this.title,
          content: this.content,
        };
        const response = await apiContractTemplate.createContractTemplate(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.templates = await apiContractTemplate.getContractsTemplate();
        // this.dialog.value = false;
        this.content = "";
        this.title = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
