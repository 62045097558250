import {API} from "./api.base.js"
export default {

  async  createContractTemplate(data) {
    try {
      const response = await API.post('/api/rest/v2/contract/template', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getContractsTemplate() {
    try {
      const response = await API.get('/api/rest/v2/contract/template');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteContractTemplate(id) {
    try {
      const response = await API.delete(`/api/rest/v2/contract/template/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateContractTemplate(id) {
    try {
      const response = await API.put(`/api/rest/v2/contract/template/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 

  async contractTemplateFilter() {
    try {
      const response = await API.get(`/api/rest/v2/contract/template/filter`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
