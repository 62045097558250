
<template>
    <ContractTemplate />
  </template>
  
  <script>
  import ContractTemplate from "@/components/contracten/ContractTemplate";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        ContractTemplate,
    },
    metaInfo: {
      title: "Welkom bij Flex vastgoed CRM | Contract ContractTemplate",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>